import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true,
  headers: {
    "Accept-Language":
      localStorage.getItem("lang") || navigator.language.split("-")[0],
  },
});
